import React from "react";
import Head from "next/head";
import styled from "styled-components";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Link from "next/link";

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background: url("/images/home-background.png") no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: rgba(0, 0, 0, 0.5);
`;
const Gradient = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.956) 0%,
    rgba(0, 0, 0, 0) 100%
  );
`;
const StyledContainer = styled(Container)`
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 26px;

  span {
    line-height: 110px;
    font-family: Audiowide;
    font-size: 90px;
    font-weight: normal;
    letter-spacing: 5px;
  }

  @media (max-width: 600px) {
    font-size: 20px;

    span {
      line-height: 80px;
      font-size: 50px;
    }
  }
`;

const Description = styled.p`
  display: none;
  margin: 0;
  font-size: 20px;
  max-width: 500px;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const ActionButton = styled(Button)`
  margin: 30px 0 0;
  padding: 15px;
  max-width: 300px;
  font-size: 20px;

  @media (max-width: 600px) {
    max-width: 100%;
    font-size: 16px;
  }
`;

const Avatar = styled.div`
  width: 100%;
  height: 70%;
  position: fixed;
  bottom: 0;

  background: url("/images/avatar.png") no-repeat;
  background-size: contain;
  background-position: center center;
`;

export default function Home() {
  return (
    <>
      <Head>
        <title>AFTERMATH | RPRP</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Background>
        <Gradient>
          <StyledContainer>
            <Avatar />
            <Content>
              <Title>
                Seja bem-vindo(a) a<br />
                <span>AFTERMATH!</span>
              </Title>
              <Description>
                Quer fazer parte da comunidade AFTERMATH? Preencha o formulário
                de allowlist e aguarde sua aprovação!
              </Description>
              <Link href="/allowlist" passHref>
                <ActionButton color="primary" variant="contained" size="large">
                  Faça allowlist
                </ActionButton>
              </Link>
            </Content>
          </StyledContainer>
        </Gradient>
      </Background>
    </>
  );
}
